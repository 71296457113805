import revive_payload_client_4sVQNw7RlN from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/igor/www/admin.qcontrol/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/igor/www/admin.qcontrol/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/igor/www/admin.qcontrol/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vfCt6Q18Tc from "/home/igor/www/admin.qcontrol/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import plugin_t2GMTTFnMT from "/home/igor/www/admin.qcontrol/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import index_NoOeBbAe38 from "/home/igor/www/admin.qcontrol/plugins/vuetify/index.js";
import index_HYtSsfhyFh from "/home/igor/www/admin.qcontrol/plugins/iconify/index.js";
import index_O6aXp1lQoC from "/home/igor/www/admin.qcontrol/plugins/i18n/index.js";
import ganttastic_client_En85f9BQ9v from "/home/igor/www/admin.qcontrol/plugins/ganttastic.client.js";
import layouts_OBoGUkHiof from "/home/igor/www/admin.qcontrol/plugins/layouts.js";
import settings_2MnfoqQ5ue from "/home/igor/www/admin.qcontrol/plugins/settings.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_vfCt6Q18Tc,
  plugin_t2GMTTFnMT,
  index_NoOeBbAe38,
  index_HYtSsfhyFh,
  index_O6aXp1lQoC,
  ganttastic_client_En85f9BQ9v,
  layouts_OBoGUkHiof,
  settings_2MnfoqQ5ue
]