export default {
  blank: () => import("/home/igor/www/admin.qcontrol/layouts/blank.vue").then(m => m.default || m),
  "components-default-layout-with-horizontal-nav": () => import("/home/igor/www/admin.qcontrol/layouts/components/DefaultLayoutWithHorizontalNav.vue").then(m => m.default || m),
  "components-default-layout-with-vertical-nav": () => import("/home/igor/www/admin.qcontrol/layouts/components/DefaultLayoutWithVerticalNav.vue").then(m => m.default || m),
  "components-footer": () => import("/home/igor/www/admin.qcontrol/layouts/components/Footer.vue").then(m => m.default || m),
  "components-nav-bar-notifications": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavBarNotifications.vue").then(m => m.default || m),
  "components-nav-search-bar": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavSearchBar.vue").then(m => m.default || m),
  "components-navbar-shortcuts": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavbarShortcuts.vue").then(m => m.default || m),
  "components-navbar-theme-switcher": () => import("/home/igor/www/admin.qcontrol/layouts/components/NavbarThemeSwitcher.vue").then(m => m.default || m),
  "components-user-profile": () => import("/home/igor/www/admin.qcontrol/layouts/components/UserProfile.vue").then(m => m.default || m),
  default: () => import("/home/igor/www/admin.qcontrol/layouts/default.vue").then(m => m.default || m)
}