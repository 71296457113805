{
  "table": {
    "actions": "Actions",
    "no_data_text": "No data available",
    "previous": "Previous",
    "next": "Next"
  },
  "menu": {
    "home": "Home",
    "projects": "Projects"
  },
  "login": {
    "title": "Welcome to Q.Control👋",
    "subtitle": "Log in to start working (you can log in using email or phone number)",
    "password": "Password",
    "forgot_password": "Forgot password?",
    "remember_me": "Remember me",
    "submit": "Log in",
    "login": "Phone number",
    "login_placeholder": "+996705554433"
  },
  "forgot_password": {
    "title": "Forgot password? 🔒",
    "subtitle": "Enter your phone number and we will send you a code",
    "send": "Send",
    "back_login": "Return to login",
    "login": "Phone number",
    "login_placeholder": "+996705554433"
  },
  "reset_password": {
    "title": "Reset password 🔒",
    "new_password": "New password",
    "confirm_password": "Confirm new password",
    "update_password": "Update password",
    "back_login": "Return to login"
  },
  "two_steps": {
    "title": "Confirmation code 💬",
    "subtitle": "We sent you an SMS with a code to your phone number",
    "type_code": "Enter the code from the SMS",
    "submit": "Submit",
    "didnot_get_code": "Didn't get the code?",
    "resend": "Resend"
  },
  "project": {
    "name": "Name",
    "count": "Count",
    "progress": "Progress",
    "date_start": "Start date",
    "date_end": "End date",
    "search_placeholder": "Search project",
    "add_button": "Create project"
  }
}
