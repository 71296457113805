export default defineNuxtPlugin(async () => {
	return {
		provide: {
			settings: {
				object: {
					priorities: [
						{
							title: 'Важный',
							value: 'high',
							color: 'success',
						},
						{
							title: 'Средний',
							value: 'medium',
							color: 'primary',
						},
						{
							title: 'Низкий',
							value: 'low',
							color: 'secondary',
						},
					],
					statuses: [
						{
							title: 'В процессе',
							value: 'inProgress',
							color: 'info',
						},
						{
							title: 'Завершен',
							value: 'completed',
							color: 'primary',
						},
						{
							title: 'Сдан',
							value: 'done',
							color: 'success',
						},
					],
					types: [
						{
							title: 'Тип один',
							value: 'type_one',
						},
						{
							title: 'Тип два',
							value: 'type_two',
						},
						{
							title: 'Тип три',
							value: 'type_three',
						},
					],
				},
				file_types: [
					{
						title: 'Pdf',
						icon: 'tabler-file-type-pdf',
						extensions: ['pdf'],
						type: 'pdf',
					},
					{
						title: 'Archive',
						icon: 'tabler-file-type-zip',
						extensions: ['zip', 'rar', '7z', 'tar'],
						type: 'archive',
					},
					{
						title: 'Image',
						icon: 'tabler-file-type-jpg',
						extensions: ['jpg', 'jpeg', 'svg', 'png', 'gif'],
						type: 'image',
					},
					{
						title: 'Document',
						icon: 'tabler-file-type-doc',
						extensions: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
						type: 'document',
					},
				],
				employee: {
					roles: [
						{
							title: 'Админ',
							value: 'admin',
						},
						{
							title: 'ПМ',
							value: 'pm',
						},
						{
							title: 'Тимлид',
							value: 'teamlead',
						},
						{
							title: 'Технадзор',
							value: 'supervision',
						},
					],
					timesheets: [
						{
							value: 'pt',
							color: 'success',
						},
						{
							value: 'ptg',
							color: 'info',
						},
						{
							value: 'npt',
							color: 'secondary',
						},
						{
							value: 'nptg',
							color: 'warning',
						},
						{
							value: 'to',
							color: 'error',
						},
					],
					task: {
						types: [
							{
								value: 'onetime',
								color: 'success',
								title: 'Единоразовая',
							},
							{
								value: 'everyday',
								color: 'info',
								title: 'Ежедневная',
							},
							{
								value: 'everyweek',
								color: 'secondary',
								title: 'Еженедельная',
							},
							{
								value: 'everymonth',
								color: 'warning',
								title: 'Ежемесячная',
							},
						],
						statuses: [
							{
								value: 'new',
								color: 'success',
								title: 'Новая',
							},
							{
								value: 'in_work',
								color: 'info',
								title: 'В работе',
							},
							{
								value: 'not_completed',
								color: 'secondary',
								title: 'Не выполнена',
							},
							{
								value: 'completed',
								color: 'warning',
								title: 'Выполнена',
							},
							{
								value: 'deleted',
								color: '',
								title: 'Удалена',
							},
						],
						levels: [
							{
								value: 'minor',
								color: 'success',
								title: 'Минор',
							},
							{
								value: 'major',
								color: 'info',
								title: 'Мажор',
							},
							{
								value: 'critical',
								color: 'warning',
								title: 'Критичная',
							},
						],
						// repeats: [
						// 	{
						// 		value: 'everyday',
						// 		title: 'Ежедневно',
						// 	},
						// 	{
						// 		value: 'everyweek',
						// 		title: 'Еженедельно',
						// 	},
						// 	{
						// 		value: 'everymonth',
						// 		title: 'Ежемесячно',
						// 	},
						// ],
					},
				},
				map: {
					mapCenter: [42.8777895, 74.6066926],
					mapZoom: 11,
					mapTile: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
					defaultCoords: {
						polygon: [
							[42.92224052343343, 74.57759857177736],
							[42.892567248047285, 74.57725524902345],
							[42.89281877649548, 74.62291717529298],
						],
						circle: [42.871837772992635, 74.58480834960939],
						marker: [42.871837772992635, 74.58480834960939],
					},
					radius: 2000,
					colors: [
						{
							key: 'red',
							title: 'красный',
						},
						{
							key: 'blue',
							title: 'синий',
						},
						{
							key: 'green',
							title: 'зеленый',
						},
						{
							key: 'black',
							title: 'черный',
						},
						{
							key: 'orange',
							title: 'оранжевый',
						},
						{
							key: 'yellow',
							title: 'желтый',
						},
						{
							key: 'indigo',
							title: 'индиго',
						},
						{
							key: 'purple',
							title: 'фиолетовый',
						},
						{
							key: 'pink',
							title: 'розовый',
						},
					],
				},
				report: {
					statuses: [
						{
							value: 'new',
							title: 'Новый',
							color: 'error',
						},
						{
							value: 'accepted',
							title: 'Принят',
							color: 'success',
						},
					],
					dates: [
						{
							value: 'today',
							title: 'Сегодня',
						},
						{
							value: 'week',
							title: 'Неделя',
						},
						{
							value: 'month',
							title: 'Месяц',
						},
					],
				},
				day_of_week: ['mon', 'tue', 'wed', 'thu', 'fri', 'set', 'sun'],
				question_values: ['ok', 'not_ok', 'nz'],
				repeats: ['onetime', 'everyday', 'everyweek', 'everymonth'],
				work: {
					reports_previous_days: false,
					daily_reports: false,
				},
			},
		},
	}
})
